import React from 'react';
import DocumentOfBusinessPartners from "../../pages/DocumentOfBusinessPartners";

const Contracts = ({ businessPartnerId, activeTab }) => {
  return (
    <DocumentOfBusinessPartners business_partner_id={businessPartnerId} activeTab={activeTab}/>
  );
};

export default Contracts;
