import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { CheckSquareIcon, CrossSquareIcon } from '../Icons';
import { useTranslation } from 'react-i18next';
import * as style from './index.module.css';
import classNames from 'classnames';
import DatePicker from '../DatePicker';
import moment from "moment";

const EditableDatePickerInput = ({ id, attribute, initialValue, handleSubmit }) => {
  const { i18n } = useTranslation()
  const [editable, setEditable] = useState(false)
  const localInitialValue = initialValue
  const [isHovered, setIsHovered] = useState(false);

  const onSubmit = async values => {
    setEditable(false)

    return handleSubmit(id, { [attribute]: moment.parseZone(values[attribute][0]).format('YYYY-MM-DD') })
  }

  const inputHtml = () => {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ [attribute]: initialValue }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={style.form}>
              <Field
                name={attribute}
                placeholder={attribute}
                render={({ input }) => (
                  <DatePicker
                    options={{
                      defaultDate: localInitialValue,
                      dateFormat: (i18n.language === "de" ? 'd.m.Y' : 'Y-m-d'),
                      onChange: input.onChange
                    }}
                  />
                )}
              />
              <div className={style['form-buttons']}>
                <button type="submit" disabled={submitting || pristine}>
                  <CheckSquareIcon className={classNames("fs-3 text-success", { "text-gray-500": submitting || pristine })} />
                </button>
                <button
                  type="button"
                  onClick={() => setEditable(false)}
                  disabled={submitting}
                >
                  <CrossSquareIcon className="fs-3 text-danger" />
                </button>
              </div>
            </div>
          </form>
        )}
      />
    )
  }

  const viewHtml = () => {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setEditable(true)}
      >
        {initialValue}
        {isHovered && (
          <i className={`ki-duotone ki-notepad-edit text-primary fs-3 ${style['editable-text-icon']}`}>
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        )}
      </div>
    )
  }
  return (
    <>
      {editable ? inputHtml() : viewHtml()}
    </>
  )
}

export default EditableDatePickerInput;
