import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { PlusIcon } from '../Icons';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DetailPanel from './DetailPanel';
import queryString from 'query-string';
import MasterData from './MasterData';
import classNames from 'classnames'
import * as style from './index.module.css';

const Staff = ({
  staff, user, roleName, companyName,
  locationName, countryName, customizing,
  driverLicenseControlledByName, currentStaff
}) => {
  const parsedQuery = queryString.parse(location.search)
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(parsedQuery.staff_page);

  const tableToolbar = () => {
    return (
      <>
        <TableButton
          className="btn-primary"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('shared.add_to')}
        />
      </>
    )
  }

  return (
    <>
      <div className='d-flex'>
        <div className={classNames("position-fixed", style['detail-panel-position'])}>
          <DetailPanel staff={staff} user={user} roleName={roleName}></DetailPanel>
        </div>
        <div className={classNames("", style['tabs-position'])}>
          <Tabs
            onSelect={(k) => setActiveTab(k)}
            defaultActiveKey={activeTab}
            id="staff-tabs"
            className="mb-3"
          >
            <Tab eventKey="master_data" title={t('master_data')} mountOnEnter={true}>
              <MasterData
                staff={staff}
                user={user}
                companyName={companyName}
                locationName={locationName}
                countryName={countryName}
                customizing={customizing}
                driverLicenseControlledByName={driverLicenseControlledByName}
                currentStaff={currentStaff}
                tableToolbar={tableToolbar}
              />
            </Tab>
            <Tab eventKey="working_times" title={t('working_times')} mountOnEnter={true}>
              {/* <Table tableToolbar={tableToolbar} /> */}
            </Tab>
            <Tab eventKey="staff_data" title={t('staff_data')} mountOnEnter={true}>
              {/* <Table tableToolbar={tableToolbar} /> */}
            </Tab>
            <Tab eventKey="payroll" title={t('payroll')} mountOnEnter={true}>
              {/* <Table tableToolbar={tableToolbar} /> */}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default Staff;
