import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../Table/EditableTextInput';
import { axiosInstance, routes } from '../../utils/api_base';
import Toastr from '../../components/Toastr';
import EditableDatePickerInput from '../Table/EditableDatePickerInput';
import Form from 'react-bootstrap/Form';

const DetailPanel = ({ staff, user, roleName }) => {
  const { t } = useTranslation()
  const [staffDetails, setStaffDetails] = useState({
    email: user.email,
    street: user.street,
    postalCode: user.postal_code,
    city: user.city,
    birthday: user.birthday,
    active: staff.active,
    show_in_dispo: staff.show_in_dispo,
  });

  const toggleHtml = (attribute) => {
    const isActive = staffDetails[attribute]
    const label = isActive ? t('yes') : t('no')
    return (
      <Form.Check
        onChange={() => updateStaff(staff.id, { [attribute]: !isActive })}
        checked={isActive}
        type="switch"
        id="combination-active-switch"
        label={label}
      />
    )
  }

  const updateStaff = (id, attrs) => {
    const params = {
      staff: {
        active: attrs.active,
        show_in_dispo: attrs.show_in_dispo,
        user_attributes: {
          id: user.id,
          email: attrs.email,
          street: attrs.street,
          postal_code: attrs.postalCode,
          city: attrs.city,
          birthday: attrs.birthday
        }
      }
    }
    axiosInstance.put(routes.staff(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setStaffDetails((prevDetails) => ({
          ...prevDetails,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }
  return (
    <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
      <div className="card mb-5 mb-xl-8">
        <div className="card-body pt-15">
          <div className="d-flex flex-center flex-column mb-5">
            <h1 className="fs-3 text-gray-800 fw-bold mb-1">{staff.name}</h1>
            <div className="fs-5 fw-semibold text-muted mb-6">{staff.role.toUpperCase()}</div>
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#kt_customer_view_details" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">
              {t('details')}
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span></div>
          </div>
          <div className="separator separator-dashed my-3"></div>
          <div id="kt_customer_view_details" className="collapse show">
            <div className="py-5 fs-6">
              <div className="badge badge-light-info d-inline">{roleName}</div>
              <div className="fw-bold mt-5">{t("personal_number")}</div>
              <div className="text-gray-600">{staff.client_specific_number}</div>
              <div className="fw-bold mt-5">{t("email")}</div>
              <div className="text-gray-600">
                <EditableTextInput
                  id={staff.id}
                  attribute="email"
                  initialValue={staffDetails.email}
                  handleSubmit={updateStaff}
                />
              </div>
              <div className="fw-bold mt-5">{t("birthday")}</div>
              <div className="text-gray-600">
                <EditableDatePickerInput
                  id={staff.id}
                  attribute='birthday'
                  initialValue={staffDetails.birthday || "2000-01-01"}
                  handleSubmit={updateStaff}
                />
              </div>
              <div className="fw-bold mt-5">{t("billing_address")}</div>
              <div className="text-gray-600">
                <EditableTextInput
                  id={staff.id}
                  attribute="street"
                  initialValue={staffDetails.street || "-"}
                  handleSubmit={updateStaff}
                />
                <EditableTextInput
                  id={staff.id}
                  attribute="postalCode"
                  initialValue={staffDetails.postalCode || "-"}
                  handleSubmit={updateStaff}
                />
                <EditableTextInput
                  id={staff.id}
                  attribute="city"
                  initialValue={staffDetails.city || "-"}
                  handleSubmit={updateStaff}
                />
              </div>
              <div className="fw-bold mt-5">{t("active")}</div>
              <div className="text-gray-600">
                {toggleHtml('active')}
              </div>
              <div className="fw-bold mt-5">{t("show_in_disposition")}</div>
              <div className="text-gray-600">
                {toggleHtml('show_in_dispo')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPanel;
