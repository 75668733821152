import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import queryString from 'query-string';
import Overview from '../../components/Machines/Overview';
import Costs from '../../components/Machines/Costs';
import Statistics from '../../components/Machines/Statistics';
import Map from '../../components/Machines/Map';
import {axiosInstance, routes} from '../../utils/api_base';
import {pushToHistory} from '../../utils/base';
import * as style from './index.module.css';
import DetailsPanel from '../../components/Machines/DetailsPanel';

const Machine = ({id}) => {
  const { t } = useTranslation()
  const parsedQuery = queryString.parse(location.search)
  const [activeTab, setActiveTab] = useState(parsedQuery.tab || 'overview')
  const [machine, setMachine] = useState({id: id})

  console.log({machine})
  const getMachine = (id) => {
    axiosInstance.get(routes.machine(id))
    .then((response) => {
      setMachine(prevState => ({
        ...prevState,
        ...response.data.data.attributes
      }))
    })
    .catch((error) => {
      console.log('ERROR', error)
    });
  }

  useEffect(() => {
    getMachine(id)
  }, [id])

  useEffect(() => {
    pushToHistory({tab: activeTab})
  }, [activeTab])

  return (
    <div className='d-flex'>
      <div className={style['details-panel']}>
        <DetailsPanel machine={machine} callback={setMachine}></DetailsPanel>
      </div>
      <div className={style['details-tabs']}>
        <Tabs
          onSelect={(k) => {
            setActiveTab(k)
            pushToHistory({tab: k})
          }}
          defaultActiveKey={activeTab}
          id="machine-tabs"
          className="mb-3"
        >
          <Tab eventKey="overview" title={t('overview')} mountOnEnter={true}>
            <Overview />
          </Tab>
          <Tab eventKey="costs" title={t('costs')} mountOnEnter={true}>
            <Costs />
          </Tab>
          <Tab eventKey="statistics" title={t('statistics')} mountOnEnter={true}>
            <Statistics />
          </Tab>
          { machine.is_tracked &&
              <Tab eventKey="map" title={t('map')} mountOnEnter={true}>
                <Map />
              </Tab>
          }
        </Tabs>
      </div>
    </div>
  );
};

Machine.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Machine;
