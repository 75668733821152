
import React from "react";
import EditableDatePickerInput from "../../Table/EditableDatePickerInput";

const StaffDatePickerInput = ({ staffId, initialValue, translation, updateStaff, attribute }) => {
  return (
    <>
      <div className="fw-bold">{translation}</div>
      <div className="text-gray-600">
        <EditableDatePickerInput
          id={staffId}
          attribute={attribute}
          initialValue={initialValue || "2000-01-01"}
          handleSubmit={updateStaff}
        />
      </div>
      <span className="separator my-5"></span>
    </>
  );
}

export default StaffDatePickerInput;
