import React, {useCallback, useRef} from 'react'
import {axiosInstance, routes} from "../../utils/api_base";
import {useTranslation} from 'react-i18next';
import { Form, Field, FormSpy } from 'react-final-form'
import ErrorField from '../Form/ErrorField';
import AsyncSelectAdapter from '../Form/AsyncSelectAdapter';
import TableButton from '../Table/TableButton';
import debounce from 'lodash/debounce';
import FieldMultiSelect from "./Form/FieldMultiSelect";
import Map from './../Map'

const CreateOrderModal = ({onClose, time, staffId = 1}) => {
  const {t, i18n} = useTranslation()
  const businessPartnerRef = useRef(null)

  console.count("Render Form")

  const getServices = (inputText) => {
    let params = { page: 1, limit: 20, active: true, serialize: 'short', query: inputText }

    console.log('CHECK BUSINESS PARTNER', businessPartnerRef.current)

    return axiosInstance.get(routes.services(), { params: params })
      .then((response) => {
        return response.data.data.map((service) => ({
          value: service.id,
          label: service.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getBusinessPartners = (inputText) => {
    let params = { page: 1, limit: 20, active: true, query: inputText }

    return axiosInstance.get(routes.businessPartners(), { params: params })
      .then((response) => {
        return response.data.data.map((bp) => ({
          value: bp.id,
          label: bp.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const loadBPOptions = useCallback(
    debounce((inputText, callback) => {
      getBusinessPartners(inputText).then((options) => callback(options));
    }, 1000), [])

  const loadServiceOptions = useCallback(
    debounce((inputText, callback) => {
      getServices(inputText).then((options) => callback(options));
    }, 1000), [])

  const onSubmit = async values => {
    console.log('values', values)
    // axiosInstance.post(routes.orders(), params)
  }

    return (
      <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true">
        <div className="row mb-5">
          <Form
            onSubmit={onSubmit}
            initialValues={{}}
            render={({ submitError, handleSubmit, submitting, pristine }) => (
              <form onSubmit={handleSubmit}>
                <>
                  <div className="col-md-6 fv-row">
                    <h3>Informationen</h3>

                    <div className="row mt-5">
                      <div className={"col-6 fv-row"}>
                        <label className="required fs-5 fw-semibold mb-2">Kunde</label>
                        <Field
                          name='business_partner'
                          component={AsyncSelectAdapter}
                          loadOptions={loadBPOptions}
                          placeholder={"Kunde"}
                          isClearable={true}
                        >
                        </Field>
                        <ErrorField name="business_partner" />
                      </div>
                    </div>
                    <div className={"col-6 fv-row"}>
                      <label className="required fs-5 fw-semibold mb-2">Dienstleistung</label>
                      <Field
                        name='services'
                        component={AsyncSelectAdapter}
                        loadOptions={loadServiceOptions}
                        placeholder={"Dienstleistung"}
                        isClearable={true}
                        isMulti={true}
                      >
                      </Field>
                      <ErrorField name="services" />
                    </div>
                    <div className={"col-6 fv-row"}>
                      <label className="required fs-5 fw-semibold mb-2">Feld</label>
                      <FieldMultiSelect businessPartnerRef={businessPartnerRef} />
                      <ErrorField name="Feld" />
                    </div>
                  </div>
                  {submitError && <div className="form-error-message">{submitError}</div>}
                  <div className="modal-footer">
                    <TableButton
                      className="btn btn-primary fw-semibold px-6"
                      type="submit"
                      title={"Create"}
                      disabled={submitting || pristine}
                    />
                    <TableButton
                      className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                      type="button"
                      onClick={onClose}
                      title={"Cancel"}
                    />
                  </div>
                </>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={change => {
                    if (change.values.business_partner) {
                        console.log(change.values.business_partner.value)
                      businessPartnerRef.current = change.values.business_partner.value
                    }
                  }}
                />
              </form>
            )}
          />
        </div>
      </div>

        // <Modal open={open} onClose={onClose} title={"Neuer Auftrag"} className={'modal-fullscreen p-9'}>
        //     <ModalContent>
        //         <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true">
        //             <div className="row mb-5">
        //                 <div className="col-md-6 fv-row">
        //                     <h3>Informationen</h3>
        //
        //                     <div className="row mt-5">
        //                         <div className={"col-6 fv-row"}>
        //                             <label className="required fs-5 fw-semibold mb-2">Kunde</label>
        //                             <Select options={businessPartners.map((entity) => ({
        //                                 id:  entity.id,
        //                                 text: entity.attributes.long_name
        //                             }))}
        //                                     onChange={(value) => setState((state) => ({
        //                                         ...state,
        //                                         businessPartnerId: value
        //                                     }))}
        //                                     multiple={false} placeholder={'Kunde'}></Select>
        //                         </div>
        //
        //                         <div className={"col-6 fv-row"}>
        //                             <label className="required fs-5 fw-semibold mb-2">Dienstleistung</label>
        //                             <Select options={serviceOptions}
        //                                     onChange={handleServiceChange}
        //                                     multiple={true} placeholder={'Dienstleistung'}></Select>
        //                         </div>
        //                     </div>
        //
        //                     <div className={"row mt-2"}>
        //                         <div className={"col-12 fv-row"}>
        //                             <label className="required fs-5 fw-semibold mb-2">Zeitraum</label>
        //                             <DateRangePicker
        //                                 placeholder=''
        //                                 language={i18n.language}
        //                                 classList="cursor-pointer form-control text-left"
        //                                 options={{
        //                                     timePicker: true,
        //                                     showDropdowns: true,
        //                                     startDate: state.startTime,
        //                                     endDate: state.endTime,
        //                                     onChange: (startTime, endTime) => setState((state) => ({ ...state, startTime, endTime })),
        //                                     autoApply: false
        //                                 }}
        //                             />
        //                         </div>
        //                     </div>
        //
        //                     <div className="row mt-2">
        //                         <div className={"col-6 fw-row"}>
        //                             <Input placeholder={"Menge"} onChange={(value) => setState((state) => ({
        //                                 ...state,
        //                                 quantity: value
        //                             }))}></Input>
        //                         </div>
        //                         <div className={"col-6 fv-row"}>
        //                             <label className="fs-5 fw-semibold mb-2">Einheit</label>
        //                             <Select
        //                                 options={(units || []).map((entity) => ({
        //                                     id:  entity.id,
        //                                     text: entity.attributes.name
        //                                 }))}
        //                                 value={state.unitId || ''}
        //                                 onChange={(value) => setState((state) => ({
        //                                     ...state,
        //                                     unitId: value
        //                                 }))}
        //                                 multiple={false} placeholder={'Einheit'}></Select>
        //                         </div>
        //                     </div>
        //
        //                     <div className="row mt-2">
        //                         <div className={"col-6 fv-row"}>
        //                             <label className="fs-5 fw-semibold mb-2">Schläge</label>
        //                             <Select options={fields.map((entity) => ({
        //                                 id:  entity.id,
        //                                 text: entity.attributes.name_with_hectare
        //                             }))}
        //                                     onChange={(values) => setState((state) => ({...state, fields: values}))}
        //                                     multiple={true} disabled={!state.businessPartnerId}
        //                                     placeholder={'Schläge'}></Select>
        //                         </div>
        //                     </div>
        //
        //                     <div className="row mt-2">
        //                         <div className={"col-12 fv-row"}>
        //                             <label
        //                                 className="fs-5 fw-semibold mb-2">Kommentar <span>für interne Zwecke</span></label>
        //                             <Textarea onChange={(value) => setState(state => ({...state, comment: value}))}/>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="col-md-6 fv-row">
        //                     <h3>Fahreraufträge</h3>
        //
        //                     <StaffOrders staffOrders={staffOrders} staffs={staffs}
        //                                  services={services} time={time} staffId={staffId} />
        //
        //                     <div className="row mt-5">
        //                         <Button onClick={() => setStaffOrders(staffOrders => [...staffOrders, {id: 1}])}>Fahrerauftrag
        //                             hinzufügen</Button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </ModalContent>
        // </Modal>
    )
}

export default CreateOrderModal
