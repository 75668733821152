
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import StaffTextInput from "./StaffTextInput";
import StaffDatePickerInput from "./StaffDatePickerInput";
import StaffSelectInput from "./StaffSelectInput";
import { axiosInstance, routes } from '../../../utils/api_base';
import Toastr from '../../../components/Toastr';

const StaffDetails = ({ staff, user, companyName, locationName, countryName }) => {
  const { t } = useTranslation()
  const [staffDetails, setStaffDetails] = useState({
    position: user.position,
    birthLocation: user.birth_location,
    education: user.education,
    entryDate: user.entry_date,
    comment: user.comment,
    country: user.country,
    phonePrivate: user.fon_private,
    mobilePrivate: user.mobile_private,
    phoneWork: user.fon_company,
    mobileWork: user.mobile_company,
    faxWork: user.fax_company,
    invoiceEmail: user.invoice_email,
    company: { value: staff.company_id, label: companyName },
    location: { value: staff.location_id, label: locationName },
    country: { value: user.country_id, label: countryName }
  });

  const updateStaff = (id, attrs) => {
    const params = {
      staff: {
        company_id: attrs.company?.value,
        location_id: attrs.location?.value,
        user_attributes: {
          id: user.id,
          position: attrs.position,
          birth_location: attrs.birthLocation,
          education: attrs.education,
          entry_date: attrs.entryDate,
          comment: attrs.comment,
          country: attrs.country,
          fon_private: attrs.phonePrivate,
          mobile_private: attrs.mobilePrivate,
          fon_company: attrs.phoneWork,
          mobile_company: attrs.mobileWork,
          fax_company: attrs.faxWork,
          invoice_email: attrs.invoiceEmail,
          country_id: attrs.country?.value
        }
      }
    }
    axiosInstance.put(routes.staff(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setStaffDetails((prevDetails) => ({
          ...prevDetails,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const getCompanies = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      company: "true",
      query: inputText
    }

    return axiosInstance.get(routes.businessPartners(), { params: params })
      .then((response) => {
        return response.data.data.map((businessPartner) => ({
          value: businessPartner.attributes.id,
          label: businessPartner.attributes.long_name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getLocations = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.locations(), { params: params })
      .then((response) => {
        return response.data.data.map((locations) => ({
          value: locations.attributes.id,
          label: locations.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const getCountries = (inputText) => {
    let params = {
      page: 1,
      limit: 20,
      query: inputText
    }

    return axiosInstance.get(routes.countries(), { params: params })
      .then((response) => {
        return response.data.data.map((country) => ({
          value: country.attributes.id,
          label: country.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  return (
    <div className="d-flex row">
      <div className="col-12 col-lg-6">
        <div className="card mb-5 mb-xl-8">
          <div className="card-body pt-2">
            <div className="py-2 fs-6">
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.position}
                translation={t("position")}
                attribute={"position"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.birthLocation}
                translation={t("birthplace")}
                attribute={"birthLocation"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.education}
                translation={t("training")}
                attribute={"education"}
                updateStaff={updateStaff}
              />
              <StaffSelectInput
                staffId={staff.id}
                initialValue={{ value: staffDetails.company.value, label: staffDetails.company.label }}
                translation={t("company")}
                attribute={"company"}
                updateStaff={updateStaff}
                getEntities={getCompanies}
              />
              <StaffSelectInput
                staffId={staff.id}
                initialValue={{ value: staffDetails.location.value, label: staffDetails.location.label }}
                translation={t("location")}
                attribute={"location"}
                updateStaff={updateStaff}
                getEntities={getLocations}
              />
              <StaffDatePickerInput
                staffId={staff.id}
                initialValue={staffDetails.entryDate}
                translation={t("entry_date")}
                attribute={"entryDate"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.comment}
                translation={t("comment")}
                attribute={"comment"}
                updateStaff={updateStaff}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="card mb-5 mb-xl-8">
          <div className="card-body pt-2">
            <div className="py-2 fs-6">
              <StaffSelectInput
                staffId={staff.id}
                initialValue={{ value: staffDetails.country.value, label: staffDetails.country.label }}
                translation={t("country")}
                attribute={"country"}
                updateStaff={updateStaff}
                getEntities={getCountries}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.phonePrivate}
                translation={t("phone_private")}
                attribute={"phonePrivate"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.mobilePrivate}
                translation={t("mobile_private")}
                attribute={"mobilePrivate"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.phoneWork}
                translation={t("phone_work")}
                attribute={"phoneWork"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.mobileWork}
                translation={t("mobile_work")}
                attribute={"mobileWork"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.mobileWork}
                translation={t("mobile_work")}
                attribute={"mobileWork"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.faxWork}
                translation={t("fax_work")}
                attribute={"faxWork"}
                updateStaff={updateStaff}
              />
              <StaffTextInput
                staffId={staff.id}
                initialValue={staffDetails.invoiceEmail}
                translation={t("email_for_documents")}
                attribute={"invoiceEmail"}
                updateStaff={updateStaff}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default StaffDetails;
