import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import queryString from 'query-string';
import Overview from '../../components/BusinessPartners/Overview';
import Statistics from '../../components/BusinessPartners/Statistics';
import Fields from '../../components/BusinessPartners/Fields';
import Prices from '../../components/BusinessPartners/Prices';
import UserProfile from '../../pages/BusinessPartner/sidebar';
import Contracts from '../../components/BusinessPartners/Contracts';
import { pushToHistory } from '../../utils/base';
import { axiosInstance, routes } from '../../utils/api_base';

const BusinessPartner = ({ id }) => {
  const { t } = useTranslation()
  const parsedQuery = queryString.parse(location.search)
  const [activeTab, setActiveTab] = useState(parsedQuery.tab || 'overview')
  const [currentCustomizing, setCurrentCustomizing] = useState(false)

  useEffect(() => {
    pushToHistory({ tab: activeTab })
  }, [activeTab])

  useEffect(() => {
    getCustomizing()
  }, [])

  const getCustomizing = () => {
    axiosInstance.get(routes.currentCustomizing())
      .then((response) => {
        setCurrentCustomizing(response.data.data.attributes)
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  }

  return (
    <>
      <div className='d-flex flex-column flex-lg-row'>
        <UserProfile/>
        <div className='flex-lg-row-fluid ms-lg-15'>
          <Tabs
            onSelect={(k) => {
              setActiveTab(k)
              pushToHistory({ tab: k })
            }}
            defaultActiveKey={activeTab}
            id="machine-tabs"
            className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
          >
            <Tab eventKey="overview" title={t('overview')} mountOnEnter={true} unmountOnExit={true}>
              <Overview />
            </Tab>
            <Tab eventKey="prices" title={t('prices')} mountOnEnter={true} unmountOnExit={true}>
              <Prices />
            </Tab>
            <Tab eventKey="fields" title={t('fields')} mountOnEnter={true} unmountOnExit={true}>
              <Fields />
            </Tab>
            <Tab eventKey="statistic" title={t('statistics')} mountOnEnter={true} unmountOnExit={true}>
              <Statistics />
            </Tab>
            {currentCustomizing?.use_yearly_treaties_in_business_partners &&
              <Tab eventKey="contracts" title={t('contracts')} mountOnEnter={true} unmountOnExit={true}>
                <Contracts businessPartnerId={id} activeTab={activeTab} />
              </Tab>}
          </Tabs>
        </div>
      </div>
    </>
  )
};

export default BusinessPartner;
