import React, { MouseEventHandler, useEffect, useMemo} from 'react';

import Select, {
    components,
    MultiValueGenericProps,
    MultiValueProps,
    OnChangeValue,
    Props,
} from 'react-select';
import {
    SortableContainer,
    SortableContainerProps,
    SortableElement,
    SortEndHandler,
    SortableHandle,
} from 'react-sortable-hoc';
import {axiosInstance, routes} from "../../../utils/api_base";
import Map from "../../Map";

const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
];

function arrayMove(array, from, to) {
    const slicedArray = array.slice();
    slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0]
    );
    return slicedArray;
}

const SortableMultiValue = SortableElement(
    (props) => {
        // this prevents the menu from being opened/closed when the user clicks
        // on a value to begin dragging it. ideally, detecting a click (instead of
        // a drag) would still focus the control and toggle the menu, but that
        // requires some magic with refs that are out of scope for this example
        const onMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        const innerProps = { ...props.innerProps, onMouseDown };
        return <components.MultiValue {...props} innerProps={innerProps} />;
    }
);

const SortableMultiValueLabel = SortableHandle(
    (props) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select);

export default function FieldMultiSelect({ businessPartnerRef }) {
    const [selected, setSelected] = React.useState([]);
    const [fields, setFields] = React.useState([])

    const fieldData = useMemo(() => fields.map(field => ({ value: field.id, label: field.name_with_hectare })), [fields])

    const getFields = async (pagination, businessPartnerId) => {
        const {pageIndex, pageSize} = pagination
        const params = {page: pageIndex + 1, limit: pageSize, business_partner_id: businessPartnerId}
        const res = await axiosInstance.get(
            routes.fields(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }

    useEffect(() => {
        if(!businessPartnerRef.current) return

        console.log("From Field Select: ", businessPartnerRef.current)

        getFields({pageIndex: 0, pageSize: 10000}, businessPartnerRef.current).then(result => {
            setFields(result.rows.map(row => row.attributes))
        })

    }, [businessPartnerRef.current]);

    const onChange = (selectedOptions) =>
        setSelected(selectedOptions);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(selected, oldIndex, newIndex);
        setSelected(newValue);
        console.log(
            'Values sorted:',
            newValue.map((i) => i?.id)
        );
    };

    return (<>
        <SortableSelect
            useDragHandle
            // react-sortable-hoc props:
            axis="xy"
            onSortEnd={onSortEnd}
            distance={4}
            // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            // react-select props:
            isMulti
            options={fieldData}
            value={selected}
            onChange={onChange}
            components={{
                // @ts-ignore We're failing to provide a required index prop to SortableElement
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
            }}
            closeMenuOnSelect={false}
        />
       <Map fields={fields}
            selected={selected}
            onClick={(field) => setSelected(selected => ([...selected, { value: field.id, label: field.name_with_hectare }]))}/>
    </>
    );
}
