import React from 'react';

const UserProfile = () => {
  return (
    <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
      <div className="card mb-5 mb-xl-8">
        <div className="card-body">
          <div className="d-flex flex-center flex-column py-5">
            <div className="symbol symbol-100px symbol-circle mb-7">
              <img src="/keen/demo1/assets/media/avatars/300-6.jpg" alt="User Avatar" />
            </div>
            <a href="#" className="fs-3 text-gray-800 text-hover-primary fw-bold mb-3">
              Emma Smith
            </a>
            <div className="mb-9">
              <div className="badge badge-lg badge-light-primary d-inline">Administrator</div>
            </div>
            <div className="fw-bold mb-3">
              Assigned Tickets
              <span className="ms-2" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Number of support tickets assigned, closed and pending this week.">
                <i className="ki-duotone ki-information fs-7"></i>
              </span>
            </div>
            <div className="d-flex flex-wrap flex-center">
              <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                <div className="fs-4 fw-bold text-gray-700">
                  <span className="w-75px">243</span>
                  <i className="ki-duotone ki-arrow-up fs-3 text-success"></i>
                </div>
                <div className="fw-semibold text-muted">Total</div>
              </div>
              <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                <div className="fs-4 fw-bold text-gray-700">
                  <span className="w-50px">56</span>
                  <i className="ki-duotone ki-arrow-down fs-3 text-danger"></i>
                </div>
                <div className="fw-semibold text-muted">Solved</div>
              </div>
              <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                <div className="fs-4 fw-bold text-gray-700">
                  <span className="w-50px">188</span>
                  <i className="ki-duotone ki-arrow-up fs-3 text-success"></i>
                </div>
                <div className="fw-semibold text-muted">Open</div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bold rotate collapsible collapsed" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">
              Details
              <span className="ms-2 rotate-180">
                <i className="ki-duotone ki-down fs-3"></i>
              </span>
            </div>
            <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
              <a href="#" className="btn btn-sm btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_update_details">
                Edit
              </a>
            </span>
          </div>
          <div className="separator"></div>
          <div id="kt_user_view_details" className="collapse">
            <div className="pb-5 fs-6">
              <div className="fw-bold mt-5">Account ID</div>
              <div className="text-gray-600">ID-45453423</div>
              <div className="fw-bold mt-5">Email</div>
              <div className="text-gray-600"><a href="#" className="text-gray-600 text-hover-primary">info@keenthemes.com</a></div>
              <div className="fw-bold mt-5">Address</div>
              <div className="text-gray-600">101 Collin Street, <br />Melbourne 3000 VIC<br />Australia</div>
              <div className="fw-bold mt-5">Language</div>
              <div className="text-gray-600">English</div>
              <div className="fw-bold mt-5">Last Login</div>
              <div className="text-gray-600">21 Feb 2024, 11:05 am</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-5 mb-xl-8">
        <div className="card-header border-0">
          <div className="card-title">
            <h3 className="fw-bold m-0">Connected Accounts</h3>
          </div>
        </div>
        <div className="card-body pt-2">
          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
            <i className="ki-duotone ki-design-1 fs-2tx text-primary me-4"></i>
            <div className="d-flex flex-stack flex-grow-1">
              <div className="fw-semibold">
                <div className="fs-6 text-gray-700">By connecting an account, you hereby agree to our <a href="#" className="me-1">privacy policy</a> and <a href="#">terms of use</a>.</div>
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="d-flex flex-stack">
              <div className="d-flex">
                <img src="/keen/demo1/assets/media/svg/brand-logos/google-icon.svg" className="w-30px me-6" alt="Google" />
                <div className="d-flex flex-column">
                  <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-bold">Google</a>
                  <div className="fs-6 fw-semibold text-muted">Plan properly your workflow</div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <input className="form-check-input" name="google" type="checkbox" value="1" id="kt_modal_connected_accounts_google" checked />
                  <span className="form-check-label fw-semibold text-muted" htmlFor="kt_modal_connected_accounts_google"></span>
                </label>
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>
            <div className="d-flex flex-stack">
              <div className="d-flex">
                <img src="/keen/demo1/assets/media/svg/brand-logos/github.svg" className="w-30px me-6" alt="GitHub" />
                <div className="d-flex flex-column">
                  <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-bold">Github</a>
                  <div className="fs-6 fw-semibold text-muted">Keep an eye on your Repositories</div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <input className="form-check-input" name="github" type="checkbox" value="1" id="kt_modal_connected_accounts_github" checked />
                  <span className="form-check-label fw-semibold text-muted" htmlFor="kt_modal_connected_accounts_github"></span>
                </label>
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>
            <div className="d-flex flex-stack">
              <div className="d-flex">
                <img src="/keen/demo1/assets/media/svg/brand-logos/slack-icon.svg" className="w-30px me-6" alt="Slack" />
                <div className="d-flex flex-column">
                  <a href="#" className="fs-5 text-gray-900 text-hover-primary fw-bold">Slack</a>
                  <div className="fs-6 fw-semibold text-muted">Integrate Projects Discussions</div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <input className="form-check-input" name="slack" type="checkbox" value="1" id="kt_modal_connected_accounts_slack" />
                  <span className="form-check-label fw-semibold text-muted" htmlFor="kt_modal_connected_accounts_slack"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer border-0 d-flex justify-content-center pt-0">
          <button className="btn btn-sm btn-light-primary">Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
