import React, { useMemo } from 'react';
import BaseTable from '../../Table';
import { axiosInstance, routes } from '../../../utils/api_base';
import { pushToHistory } from '../../../utils/base';
import { useTranslation } from 'react-i18next';
import EditableTextInput from '../../Table/EditableTextInput';
import { TrashSquareIcon } from '../../../components/Icons';
import * as style from './index.module.css';
import Toastr from '../../../components/Toastr';

const DocumentsTable = ({ tableToolbar, staffId, trigger, setTrigger }) => {
  const { t, i18n } = useTranslation();

  const getDocuments = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], staff_id: staffId }

    if (globalFilter) {
      params.query = globalFilter
    }
    console.log('Documents params:', params);

    const res = await axiosInstance.get(routes.documentOfResources(), { params: params })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const updateDocument = (id, params) => {
    axiosInstance.put(routes.documentOfResource(id), params)
      .then(resp => {
        Toastr({ message: t("document_update_success"), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const deleteHtml = (row) => {
    return (
      <div className={`${style['trash-button']}`} onClick={() => deleteDocument(row.original.id)}>
        <TrashSquareIcon className="fs-2hx text-danger" />
      </div>
    )
  }

  const deleteDocument = (id) => {
    axiosInstance.delete(routes.documentOfResource(id))
      .then(resp => {
        Toastr({ message: t('document_delete_success'), options: { showDuration: 2000 } })
        setTrigger(Math.floor(Date.now() / 1000)) // Dirty hack to refresh the table
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const columns = useMemo(
    () => [
      {
        id: 'document_file_name',
        header: t('document'),
        size: 10,
        accessorFn: row => row?.attributes?.document_file_name,
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes
          return <a key={attributes.id} href={attributes.download_document_link}>{attributes.document_file_name}</a>
        },
      },
      {
        id: 'comment',
        header: t("comment"),
        size: 250,
        minSize: 250,
        enableSorting: false,
        cell: ({ row }) => <EditableTextInput
          id={row?.original?.id}
          attribute='comment'
          initialValue={row?.original?.attributes?.comment || "-"}
          handleSubmit={updateDocument}
        />
      },
      {
        id: 'date',
        header: t('date'),
        accessorFn: row => row?.attributes?.date,
        enableSorting: true,
        cell: row => row?.getValue(),
      },
      {
        id: 'delete',
        header: null,
        cell: ({ row }) => {
          return deleteHtml(row)
        },
      }
    ],
    [i18n.language]
  )

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#staff-documents" role="button">
          {t('documents')}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"></i>
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="staff-documents" className="collapse show">
        <BaseTable
          keyName="documents"
          getDataFn={getDocuments}
          columns={columns}
          tableToolbar={tableToolbar}
          trigger={trigger}
        />
      </div >
    </>
  )
}

export default DocumentsTable;

