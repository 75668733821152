import React from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../Toastr';
import { axiosInstance, routes } from '../../../utils/api_base';
import { Form, Field } from 'react-final-form'
import TableButton from '../../Table/TableButton';
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'
import Dropzone from '../../Form/Dropzone'


const CreateDocument = ({ onClose, setTrigger, staff }) => {
  const { t } = useTranslation();

  const onSubmit = async values => {
    console.log('values', values)
    const data = {
      ressource_id: values.ressource_id,
      document_file_name: values.document_file_name,
      comment: values.comment,
      attachment: values.attachment
    };

    axiosInstance.post(routes.documentOfResources(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()
        setTrigger(true)
      })
      .catch(error => {
        const errorData = error.response.data

        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }


  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ressource_id: staff?.id,
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('qualification')}</label>
                <Field
                  name="document_file_name"
                  component="input"
                  type="text"
                  className="form-control"
                  autoFocus
                />
              </div>
            </div>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('comment')}</label>
                <Field
                  name="comment"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row px-7 mb-3">
              <div className="col">
                <label>{t('file')}</label>
                <Field name="attachment">
                  {props => (
                    <div>
                      <Dropzone {...props.input} />
                    </div>
                  )}
                </Field>
              </div>
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </form>
        </>
      )}
    />
  )
}

export default CreateDocument;
