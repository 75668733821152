
import React from "react";
import EditableTextInput from "../../Table/EditableTextInput";

const StaffTextInput = ({ staffId, initialValue, translation, updateStaff, attribute }) => {
  return (
    <>
      <div className="fw-bold">{translation}</div>
      <div className="text-gray-600">
        <EditableTextInput
          id={staffId}
          attribute={attribute}
          initialValue={initialValue || "-"}
          handleSubmit={updateStaff}
        />
      </div>
      <span className="separator my-5"></span>
    </>
  );
}

export default StaffTextInput;
