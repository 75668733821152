import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../../utils/api_base';
import Toastr from '../../../components/Toastr';
import StaffToggleInput from "./StaffToggleInput";
import StaffDatePickerInput from "./StaffDatePickerInput";
import Button from "../../Button";

const DriverLicenses = ({ staff, user, currentStaff }) => {
  const { t } = useTranslation()
  const [licenses, setLicenses] = useState({
    motorcycleA: user.motorcycle_a,
    motorcycleA1: user.motorcycle_a1,
    motorcycleM: user.motorcycle_m,
    passengerCarB: user.passenger_car_b,
    passengerCarBE: user.passenger_car_be,
    passengerCarS: user.passenger_car_s,
    specialClassT: user.special_class_t,
    specialClassL: user.special_class_l,
    pestManagement: user.pest_management,
    chopper: user.chopper,
    combineHarvester: user.combine_harvester,
    truckC: user.truck_c,
    truckCE: user.truck_ce,
    truckC1: user.truck_c1,
    truckC1E: user.truck_c1e,
    truckEnd: user.truck_end,
    busD: user.bus_d,
    busDE: user.bus_de,
    busD1: user.bus_d1,
    busD1E: user.bus_d1e,
    driverLicenceControlledAt: user.driver_licence_controlled_at,
    driverLicenceControlledById: user.driver_licence_controlled_by_id,
    driverLicenseControlledByName: currentStaff.name
  });

  const handleToggle = (attr) => {
    const newState = { ...licenses, [attr]: !licenses[attr] };
    const attrs = { id: staff.id, ...newState };

    updateStaff(staff.id, attrs, newState);
  };

  const updateStaff = (id, attrs) => {
    const params = {
      staff: {
        user_attributes: {
          id: user.id,
          motorcycle_a: attrs.motorcycleA,
          motorcycle_a1: attrs.motorcycleA1,
          motorcycle_m: attrs.motorcycleM,
          passenger_car_b: attrs.passengerCarB,
          passenger_car_be: attrs.passengerCarBE,
          passenger_car_s: attrs.passengerCarS,
          special_class_t: attrs.specialClassT,
          special_class_l: attrs.specialClassL,
          pest_management: attrs.pestManagement,
          chopper: attrs.chopper,
          combine_harvester: attrs.combineHarvester,
          truck_c: attrs.truckC,
          truck_ce: attrs.truckCE,
          truck_c1: attrs.truckC1,
          truck_c1e: attrs.truckC1E,
          truck_end: attrs.truckEnd,
          bus_d: attrs.busD,
          bus_de: attrs.busDE,
          bus_d1: attrs.busD1,
          bus_d1e: attrs.busD1E,
          driver_licence_controlled_at: attrs.driverLicenceControlledAt,
          driver_licence_controlled_by_id: attrs.driverLicenceControlledById
        }
      }
    }
    axiosInstance.put(routes.staff(id), params)
      .then(resp => {
        Toastr({ message: t('success'), options: { showDuration: 2000 } })
        setLicenses((prevLicenses) => ({
          ...prevLicenses,
          ...attrs,
        }));
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  return (
    <>
      <div className="d-flex flex-stack fs-4 py-3">
        <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#master-data" role="button">
          {t('driver_license_classes')}
          <span className="ms-2 rotate-180">
            <i className="ki-duotone ki-down fs-3"></i>
          </span>
        </div>
      </div>
      <div className="separator my-3"></div>
      <div id="master-data" className="collapse show">
        <div className="d-flex row">
          <div className="col-12 col-lg-6 mb-10">
            <div className="card mb-5 mb-xl-8">
              <div className="card-body pt-2">
                <div className="py-2 fs-6">
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.motorbike_a")}
                    attribute={"motorcycleA"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.motorbike_a1")}
                    attribute={"motorcycleA1"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.motorbike_m")}
                    attribute={"motorcycleM"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.pkw_b")}
                    attribute={"passengerCarB"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.pkw_be")}
                    attribute={"passengerCarBE"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.pkw_s")}
                    attribute={"passengerCarS"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.special_class_t")}
                    attribute={"specialClassT"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.special_class_l")}
                    attribute={"specialClassL"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.crop_protection")}
                    attribute={"pestManagement"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.chopper")}
                    attribute={"chopper"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.harvester")}
                    attribute={"combineHarvester"}
                    handleToggle={handleToggle}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-10">
            <div className="card mb-5 mb-xl-8">
              <div className="card-body pt-2">
                <div className="py-2 fs-6">
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.lkw_c")}
                    attribute={"truckC"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.lkw_ce")}
                    attribute={"truckCE"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.lkw_c1")}
                    attribute={"truckC1"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.lkw_c1e")}
                    attribute={"truckC1E"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.bus_d")}
                    attribute={"busD"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.bus_de")}
                    attribute={"busDE"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.bus_d1")}
                    attribute={"busD1"}
                    handleToggle={handleToggle}
                  />
                  <StaffToggleInput
                    staffId={staff.id}
                    boolState={licenses}
                    translation={t("licenses.bus_d1e")}
                    attribute={"busD1E"}
                    handleToggle={handleToggle}
                  />
                  <StaffDatePickerInput
                    staffId={staff.id}
                    initialValue={licenses.truckEnd}
                    translation={t("licenses.expiry_date_truck")}
                    attribute={"truckEnd"}
                    updateStaff={updateStaff}
                  />
                  <Button
                    onClick={() => {
                      const attrs = {
                        "driverLicenceControlledAt": new Date().toISOString().split('T')[0],
                        "driverLicenceControlledById": currentStaff.id,
                        "driverLicenseControlledByName": currentStaff.name
                      };

                      updateStaff(staff.id, attrs)
                    }}
                  >
                    {t("mark_as_controlled")}
                  </Button>
                  {licenses.driverLicenceControlledAt && <span>{t("mark_as_controlled_text", { date: licenses.driverLicenceControlledAt, name: licenses.driverLicenseControlledByName })}</span>}
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};


export default DriverLicenses;
