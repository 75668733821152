import './i18n'
import ReactOnRails from 'react-on-rails';
import appStore from '../stores/appStore';

ReactOnRails.registerStore({
  appStore
});
//

import '../sources/js/main'
import "../css/application.scss"
import "../css/global.scss"
import "../css/variables.scss"

import ActiveStaffPerMonths from '../pages/ActiveStaffPerMonths';
import ArticleBills from '../pages/ArticleBills';
import ArticleConsumptions from '../pages/ArticleConsumptions';
import ArticleDisposals from '../pages/ArticleDisposals';
import ArticleGroups from '../pages/ArticleGroups';
import ArticlePurchases from '../pages/ArticlePurchases';
import Articles from '../pages/Articles';
import Bills from '../components/Bills';
import Bookings from '../pages/Bookings';
import BookingResults from '../pages/BookingResults';
import BusinessPartner from '../pages/BusinessPartner/show';
import BusinessPartners from '../components/BusinessPartners';
import BusinessPartnerWithoutInvoices from '../pages/BusinessPartnerWithoutInvoices';
import BusinessPartnersChooseCompany from '../pages/BusinessPartnersChooseCompany';
import ChroniclesStaffPerDay from '../pages/ChroniclesStaffPerDay';
import Combinations from '../pages/Combinations';
import DeliverySlips from '../components/DeliverySlips';
import Disposition from '../pages/Disposition'
import DocumentOfBookingReports from '../pages/DocumentOfBookingReports';
import Dunnings from '../components/Dunnings';
import Footer from '../components/layouts/Footer';
import JobProgressBar from '../components/JobProgressBar';
import Languages from '../components/layouts/Header/Languages';
import LicenseChronicles from '../pages/LicenseChronicles';
import MachineGroupForServiceFeatures from '../pages/MachineGroupForServiceFeatures';
import MachineGroupStatistics from '../pages/MachineGroupStatistics';
import MachineRepairs from '../pages/MachineRepairs';
import Machine from '../pages/Machines/show';
import Machines from '../components/Machines';
import MakeMeHappy from '../components/MakeMeHappy';
import Offers from '../components/Offers';
import Orders from '../components/Orders';
import OrderWithoutInvoices from '../pages/OrderWithoutInvoices';
import PlantProtectionProducts from '../pages/PlantProtectionProducts';
import Popover from '../components/Popover';
import PurchaseBills from '../components/PurchaseBills';
import RemunerationPayrolls from '../pages/RemunerationPayrolls';
import RentalMachines from '../pages/RentalMachines';
import RessourceLocationHistories from '../pages/RessourceLocationHistories';
import RevenueAccounts from '../pages/RevenueAccounts';
import RevenueCodes from '../pages/RevenueCodes';
import SelectPeriod from '../components/SelectPeriod';
import ServiceBusinessPartnerStatistics from '../components/ServiceBusinessPartnerStatistics';
import ServiceFeatureChronicles from '../components/ServiceFeatureChronicles';
import ServiceFeatures from '../components/ServiceFeatures';
import ServiceGroupStatistics from '../pages/ServiceGroupStatistics';
import Services from '../components/Services';
import Sites from '../components/Sites';
import StaffOvertime from '../pages/StaffOvertime';
import Staff from '../components/Staffs/show';
import Staffs from '../components/Staffs';
import Toastr from '../components/Toastr';
import UeberDunningBusinessPartner from '../pages/UeberDunningBusinessPartner';
import UeberDunnings from '../pages/UeberDunnings';
import Units from '../components/Units';
import Vendors from '../pages/Vendors';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  ActiveStaffPerMonths,
  ArticleBills,
  ArticleConsumptions,
  ArticleDisposals,
  ArticleGroups,
  ArticlePurchases,
  Articles,
  Bills,
  Bookings,
  BookingResults,
  BusinessPartner,
  BusinessPartners,
  BusinessPartnerWithoutInvoices,
  BusinessPartnersChooseCompany,
  ChroniclesStaffPerDay,
  Combinations,
  DeliverySlips,
  Disposition,
  DocumentOfBookingReports,
  Dunnings,
  Footer,
  JobProgressBar,
  Languages,
  LicenseChronicles,
  Machine,
  MachineGroupForServiceFeatures,
  MachineGroupStatistics,
  MachineRepairs,
  Machines,
  MakeMeHappy,
  Offers,
  Orders,
  OrderWithoutInvoices,
  PlantProtectionProducts,
  Popover,
  PurchaseBills,
  RemunerationPayrolls,
  RentalMachines,
  RessourceLocationHistories,
  RevenueAccounts,
  RevenueCodes,
  SelectPeriod,
  ServiceBusinessPartnerStatistics,
  ServiceFeatureChronicles,
  ServiceFeatures,
  ServiceGroupStatistics,
  Services,
  Sites,
  StaffOvertime,
  Staff,
  Staffs,
  Toastr,
  UeberDunningBusinessPartner,
  UeberDunnings,
  Units,
  Vendors,
});
