import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import StaffDetails from "./StaffDetails";
import DriverLicenses from "./DriverLicenses";
import DocumentsTable from "./DocumentsTable";
import QualificationsTable from "./QualificationsTable";
import CreateQualification from "./CreateQualification";
import CreateDocument from "./CreateDocument";
import Modal from '../../Modal';
import TableButton from "../../Table/TableButton";
import { PlusIcon } from '../../Icons';

const MasterData = ({
  staff, user, companyName, locationName, countryName,
  customizing, driverLicenseControlledByName, currentStaff
}) => {
  const { t } = useTranslation();
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [qualificationModalOpen, setQualificationModalOpen] = useState(false);
  const [qualificationsTrigger, setQualificationsTrigger] = useState(false);
  const [documentsTrigger, setDocumentsTrigger] = useState(false);

  const DocumentsTableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setDocumentModalOpen(true)}
        icon={<PlusIcon />}
        title={t('new')}
      />
    )
  }

  const QualificationsTableToolbar = () => {
    return (
      <TableButton
        className="btn-primary"
        onClick={() => setQualificationModalOpen(true)}
        icon={<PlusIcon />}
        title={t('new')}
      />
    )
  }

  return (
    <>
      <StaffDetails staff={staff} user={user} companyName={companyName} locationName={locationName} countryName={countryName} />
      {customizing.show_drivers_licenses_at_staffs &&
        <DriverLicenses user={user} staff={staff} driverLicenseControlledByName={driverLicenseControlledByName} currentStaff={currentStaff} />
      }
      <>
        <Modal className="modal-lg" open={documentModalOpen} onClose={() => setDocumentModalOpen(false)} title={t('documents')}>
          <CreateDocument onClose={() => setDocumentModalOpen(false)} setTrigger={setDocumentsTrigger} staff={staff} />
        </Modal>
        <DocumentsTable staffId={staff.id} tableToolbar={DocumentsTableToolbar} setTrigger={setDocumentsTrigger} trigger={documentsTrigger} />
      </>
      <>
        <Modal className="modal fade show" open={qualificationModalOpen} onClose={() => setQualificationModalOpen(false)} title={t('qualifications')}>
          <CreateQualification onClose={() => setQualificationModalOpen(false)} setTrigger={setQualificationsTrigger} staff={staff} />
        </Modal>
        <QualificationsTable staffId={staff.id} tableToolbar={QualificationsTableToolbar} setTrigger={setQualificationsTrigger} trigger={qualificationsTrigger} />
      </>
    </>
  );
};

export default MasterData;
