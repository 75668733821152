import React, { useEffect, useRef } from 'react';

const Map = ({ latitude, longitude, disableDefaultUI, fields = [], selected = [], onClick = (field) => null }) => {
    const mapRef = useRef(null);
    const boundsRef = useRef(new window.google.maps.LatLngBounds());
    const googleMapRef = useRef(null);

    const buildFields = (data) => {
        return data.map((fieldData) => {
            const path = geojsonToPath(fieldData.poly);
            const options = {
                strokeColor: fieldData.used_in_order ? '#74E854' : fieldData.disposed_in_order ? '#E87954' : '#0066ff',
                strokeWeight: 1,
                fillColor: fieldData.used_in_order ? '#74E854' : fieldData.disposed_in_order ? '#E87954' : '#0066ff',
                fillOpacity: 0.3,
                paths: path,
                id: fieldData.id,
                name: fieldData.name_with_hectare,
                latitude: fieldData.latitude,
                longitude: fieldData.longitude
            };
            const polygon = new window.google.maps.Polygon(options);
            polygon.setMap(googleMapRef.current);

            // Füge einen Marker hinzu
            const markerPosition = new window.google.maps.LatLng(fieldData.latitude, fieldData.longitude);


            const createSvgWithText = (text) => {
                const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");

                // Setze den Textinhalt und andere Attribute für das Text-Element
                textElement.setAttribute("x", "0");
                textElement.setAttribute("y", "15");
                textElement.setAttribute("font-size", "12");
                textElement.setAttribute("font-family", "Arial");
                textElement.textContent = text;

                // Füge das Text-Element dem SVG hinzu
                svgElement.appendChild(textElement);

                // Füge das SVG dem DOM hinzu (temporär, um die Breite des Textes zu messen)
                document.body.appendChild(svgElement);

                // Messen der Textbreite
                const textWidth = textElement.getBBox().width;

                // Entferne das temporäre SVG aus dem DOM
                document.body.removeChild(svgElement);

                // Erstelle das finale SVG mit dynamischer Breite basierend auf der Textbreite
                const svgString = `
                <svg xmlns="http://www.w3.org/2000/svg" width="${textWidth + 20}" height="30">
                    <rect x="0" y="0" width="${textWidth + 10}" height="20" fill="white" stroke="black" rx="3" ry="3" />
                    <text x="50%" y="40%" alignment-baseline="middle" text-anchor="middle" fill="black" font-size="12" font-family="Arial">${text}</text>
                </svg>
    `;

                return {
                    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgString)}`,
                    width: textWidth + 20,  // Breite des SVGs inklusive Padding
                    height: 30  // Höhe des SVGs
                };
            };

            // Jetzt passe den Marker an
            const fieldDataSvg = createSvgWithText(fieldData.name_with_hectare);

            const marker = new window.google.maps.Marker({
                position: markerPosition,
                map: googleMapRef.current,
                icon: {
                    url: fieldDataSvg.url, // Die URL für das dynamische SVG
                    scaledSize: new window.google.maps.Size(fieldDataSvg.width, fieldDataSvg.height), // Dynamische Größe basierend auf Textbreite und Höhe
                    anchor: new window.google.maps.Point(fieldDataSvg.width / 2, fieldDataSvg.height / 2) // Zentriere den Anker in der Mitte des SVGs
                }
            });


            // Klick-Event für das Polygon
            polygon.addListener('click', () => {
                onClick(fieldData)
            });

            return { polygon, marker, ...fieldData };
        });
    };

    const showFieldsOnMap = (fields) => {
        if(fields.length === 0) return

        fields.forEach(field => {
            field.polygon.setMap(googleMapRef.current);
            boundsRef.current.extend(new window.google.maps.LatLng(field.latitude, field.longitude));
        });
        googleMapRef.current.fitBounds(boundsRef.current);
    };

    const geojsonToPath = (geojson) => {
        const path = [];
        if (geojson) {
            geojson.coordinates.forEach((ext) => {
                ext.forEach((coordinate) => {
                    const latLng = new window.google.maps.LatLng(coordinate[1], coordinate[0]);
                    path.push(latLng);
                });
            });
        }
        return path;
    };

    useEffect(() => {
        const initMap = () => {
            const mapElement = mapRef.current

            // Set center based on provided lat/long or default values
            const center = latitude && longitude
                ? new window.google.maps.LatLng(latitude, longitude)
                : new window.google.maps.LatLng(52.5149672988683, 13.3901882171631);

            const mapOptions = {
                tilt: 0, // Prevent incorrect click lat/long issue
                mapTypeId: window.google.maps.MapTypeId.HYBRID,
                disableDefaultUI: disableDefaultUI,
                gestureHandling: 'greedy',
                center: center,
                zoom: 15
            };

            // Create the map instance
            googleMapRef.current = new window.google.maps.Map(mapElement, mapOptions);
        };

        initMap();
        showFieldsOnMap(buildFields(fields))
    }, [latitude, longitude, disableDefaultUI, fields]);

    const fitBounds = () => {
        if (!boundsRef.current.isEmpty()) {
            googleMapRef.current.fitBounds(boundsRef.current);
            window.google.maps.event.addListenerOnce(googleMapRef.current, 'bounds_changed', () => {
                const currentZoom = googleMapRef.current.getZoom();
                if (currentZoom && currentZoom > 15) {
                    googleMapRef.current.setZoom(15);
                }
            });
        }
    };

    // You can trigger fitBounds() when needed by calling it

    return (
        <div ref={mapRef} style={{ height: '100vh', width: '100%' }}>
            {/* The map will be rendered in this div */}
        </div>
    );
};

export default Map;
