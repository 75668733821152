import React, { useState, useMemo } from 'react'
import queryString from 'query-string';
import Pagination from './Pagination'
import { SearchIcon } from '../Icons';
import DebounceInput from '../../core/DebounceInput';
import { Skeleton } from '../../core/Skeleton';
import Filters from './Filters';
import { pick, map } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

const queryClient = new QueryClient()

const Table = ({
  columns, columnVisibility, getDataFn, tableToolbar, keyName = "data",
  trigger = false, tableFooter, initialSortDirection = 'desc', enablePagination = true,
  showSearchInput = true, enableSorting = true, sortBy = null, filters = [], filterMultiColumns = false
}) => {
  const parsedQuery = queryString.parse(location.search)
  const { t } = useTranslation()

  const defaultCurrentPage = parsedQuery.page ? Number(parsedQuery.page) - 1 : 0
  const [defaultSortAttribute, defaultSortDirection] = parsedQuery.sort?.split(' ') || []

  const customFiltersQueryValues = pick(parsedQuery, map(filters, 'attribute'))

  const [pagination, setPagination] = useState({
    pageIndex: defaultCurrentPage,
    pageSize: parsedQuery.limit ?? 10
  })
  const [sorting, setSorting] = useState(() => {
    if (enableSorting) {
      return [{
        id: sortBy ?? defaultSortAttribute ?? columns[0].id,
        desc: defaultSortDirection ?? initialSortDirection === 'desc'
      }];
    }

    return null
  });
  const [globalFilter, setGlobalFilter] = useState(parsedQuery.query ?? '')

  const [customFilters, setCustomFilters] = useState(customFiltersQueryValues)

  const dataQuery = useQuery({
    queryKey: [keyName, pagination, sorting, globalFilter, trigger, customFilters],
    queryFn: () => getDataFn(pagination, sorting, globalFilter, customFilters),
  })

  const isLoading = dataQuery.isLoading

  const tableData = useMemo(
    () => (isLoading ? Array(10).fill({}) : dataQuery.data?.rows),
    [isLoading, sorting, globalFilter, pagination, customFilters, trigger]
  )

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
          ...column,
          cell: <Skeleton />,
        }))
        : columns,
    [isLoading, columns]
  )

  const tableFilters = useMemo(() => {
    return <Filters filters={filters} initialValues={customFilters} setFilters={setCustomFilters} multiColumns={filterMultiColumns} />
  }, [customFilters])

  const table = useReactTable({
    data: tableData,
    rowCount: dataQuery.data?.rowCount,
    pageCount: dataQuery.data?.pageCount,
    meta: dataQuery.data?.meta,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    state: {
      globalFilter,
      sorting,
      pagination,
      columnVisibility: columnVisibility
    },
    manualPagination: true,
  })

  const hasRows = table.getRowModel().rows.length > 0;

  return (
    <div className="card card-flush">
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          {showSearchInput &&
            <div className="d-flex align-items-center position-relative my-1">
              <SearchIcon />
              <DebounceInput initialValue={globalFilter} onChange={setGlobalFilter}>
                <input disabled={isLoading} type="text" className="form-control form-control-solid w-250px ps-13" placeholder="Search" />
              </DebounceInput>
            </div>
          }
        </div>
        <div className="card-toolbar">
          <div className="d-flex justify-content-end">
            {filters.length > 0 && tableFilters}
            {tableToolbar()}
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <div id="kt_ecommerce_sales_table_wrapper" className="dt-container dt-bootstrap5 dt-empty-footer">
          <div className="row mt-2 justify-content-md-center">
            <div className="col-12 table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0" key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <i className="ki-duotone ki-up" />,
                                  desc: <i className="ki-duotone ki-down" />,
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            </>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {hasRows ? (
                    table.getRowModel().rows.map(row => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id} style={{ width: cell.column.getSize() }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={table.getAllColumns().length} className="text-center">
                        {t('shared.no_data')}
                      </td>
                    </tr>
                  )}
                </tbody>
                {tableFooter && (
                  <tfoot>{tableFooter}</tfoot>
                )}
                <tfoot className="fw-bold text-black">
                  {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map(footer => (
                        <th key={footer.id}>
                          {flexRender(footer.column.columnDef.footer, footer.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            </div>
          </div>
          {hasRows && enablePagination && (
            <div className="row mt-2 justify-content-between">
              <div className="d-md-flex justify-content-between align-items-center dt-layout-start col-md-auto me-auto"></div>
              <div className="d-md-flex justify-content-between align-items-center dt-layout-end col-md-auto ms-auto">
                <Pagination table={table} currentPage={pagination.pageIndex + 1} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const BaseTable = (props) => {

  return (
    <QueryClientProvider client={queryClient}>
      <Table {...props} />
    </QueryClientProvider>
  )
}

export default BaseTable

