import React, { useMemo } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';

const Table = ({ filters, tableToolbar, startDate, endDate }) => {
  const { t, i18n } = useTranslation()

  const getBookings = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination

    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })

    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], start_date: startDate, end_date: endDate }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.bookings(), { params: params })
    pushToHistory(params)

    return ({ rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count })
  }

  const bookingAmountStyle = (amount) => {
    const numericAmount = parseFloat(amount.replace(/[^\d.-]/g, ''));

    if (numericAmount < 0) {
      return <p style={{ color: "red" }}>{amount}</p>
    } else {
      return <p style={{ color: "green" }}>{amount}</p>
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'booking_id',
        header: t('id'),
        accessorFn: row => row?.attributes?.id,
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes

          return <a key={attributes?.id} href={attributes?.booking_link}>{attributes?.id}</a>
        },
      },
      {
        id: 'business_partner_number',
        header: t('customer'),
        accessorFn: row => row?.attributes?.business_partner_number,
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes

          return <a key={attributes?.id} href={attributes?.business_partner_link}>{attributes?.business_partner_number}</a>
        },
      },
      {
        id: 'order_id',
        header: t('order'),
        accessorFn: row => row?.attributes?.order_id,
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes

          return <a key={attributes?.id} href={attributes?.order_link}>{attributes?.order_id}</a>
        },
      },
      {
        id: 'bill_number',
        header: t('bill'),
        accessorFn: row => row?.attributes?.bill_number,
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes

          return <a key={attributes?.id} href={attributes?.bill_link}>{attributes?.bill_number}</a>
        },
      },
      {
        id: 'resource_name',
        header: t('resource'),
        accessorFn: row => row?.attributes?.resource_name,
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes

          return <a key={attributes?.id} href={attributes?.resource_link}>{attributes?.resource_name}</a>
        },
      },
      {
        id: 'order_location',
        header: t('order_location'),
        accessorFn: row => row?.attributes?.order_location,
        cell: row => row.getValue(),
      },
      {
        id: 'resource_location',
        header: t('resource_location'),
        accessorFn: row => row?.attributes?.resource_location,
        cell: row => row.getValue(),
      },
      {
        id: 'booking_code',
        header: t('booking_code'),
        accessorFn: row => row?.attributes?.booking_code,
        cell: row => row.getValue(),
      },
      {
        id: 'service_name',
        header: t('service'),
        accessorFn: row => row?.attributes?.service_name,
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes

          return <a key={attributes?.id} href={attributes?.service_link}>{attributes?.service_name}</a>
        },
      },
      {
        id: 'quantity_with_units',
        header: t('quantity'),
        accessorFn: row => row?.attributes?.quantity_with_units,
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'times',
        header: t('period'),
        accessorFn: row => row?.attributes?.times,
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'booking_amount',
        header: t('amount'),
        accessorFn: row => bookingAmountStyle(row?.attributes?.booking_amount),
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'comment',
        header: t('comment'),
        accessorFn: row => row?.attributes?.comment,
        cell: row => row.getValue(),
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getBookings}
      tableToolbar={tableToolbar}
      filters={filters}
      filterMultiColumns={true}
      initialSortDirection='asc'
      trigger={startDate + endDate}
    />
  )
}

export default Table;
