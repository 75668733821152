import React, { useState, useCallback } from 'react'
import { Form, Field } from 'react-final-form'
import { CheckSquareIcon, CrossSquareIcon } from '../Icons';
import * as style from './index.module.css';
import classNames from 'classnames';
import AsyncSelectAdapter from '../Form/AsyncSelectAdapter';
import debounce from "lodash/debounce";

// TODO: Add close callback after submiting and editableAfterSubmit is false
const EditableSelectInput = ({ id, attribute, initialValue, handleSubmit, getEntities, fieldStyle = "", editableAfterSubmit = true }) => {
  const [editable, setEditable] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [optionsEmpty, setOptionsEmpty] = useState(false);

  const onSubmit = async values => {
    setEditable(false)
    const selectedOption = values[attribute] || {};

    return handleSubmit(id, { [attribute]: selectedOption });

    // return handleSubmit(id, { [attribute]: values[attribute]?.value || null })
  }

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getEntities(inputText, id).then((options) => {
        setOptionsEmpty(options.length === 0);
        callback(options)
      });
    }, 1000), [])

  const inputHtml = () => {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ [attribute]: initialValue || '' }}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={style.form}>
              <Field
                name={attribute}
                component={AsyncSelectAdapter}
                loadOptions={loadOptions}
                placeholder={attribute}
                isClearable={true}
                className={fieldStyle}
              />
              <div className={style['form-buttons']}>
                <button type="submit" disabled={submitting || pristine}>
                  <CheckSquareIcon className={classNames("fs-3 text-success", { "text-gray-500": submitting || pristine })} />
                </button>
                <button
                  type="button"
                  onClick={() => setEditable(false)}
                  disabled={submitting}
                >
                  <CrossSquareIcon className="fs-3 text-danger" />
                </button>
              </div>
            </div>
          </form>
        )}
      />
    )
  }

  const viewHtml = () => {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setEditable(true)}
      >
        {initialValue.label || 'N/A'}
        {isHovered && (
          <i className={`ki-duotone ki-notepad-edit text-primary fs-3 ${style['editable-text-icon']}`}>
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        )}
      </div>
    )
  }

  return (
    <>
      {editable ? inputHtml() : viewHtml()}
    </>
  )
}

export default EditableSelectInput
