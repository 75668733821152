import React from "react";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../../ToggleSwitch";


const StaffToggleInput = ({ boolState, translation, handleToggle, attribute }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="fw-bold me-5">{translation}</div>
        <div className="text-gray-600">
          <ToggleSwitch
            handleToggle={handleToggle}
            label={boolState[attribute] ? t('yes') : t('no')}
            attribute={attribute}
            active={boolState[attribute]}
          />
        </div>
      </div>
      <span className="separator my-5"></span>
    </>
  );
}

export default StaffToggleInput;
