import React, { useState } from "react";

const ToggleSwitch = ({ label, handleToggle, isDisabled = false, active = false, attribute }) => {
  const [isChecked, setIsChecked] = useState(active);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);

    handleToggle(attribute);
  };

  return (
    <div className="form-check form-switch form-check-warning form-check-solid">
      <input
        className="form-check-input"
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        disabled={isDisabled}
      />
      <label className="form-check-label">
        {label}
      </label>
    </div>
  );
};

export default ToggleSwitch;
