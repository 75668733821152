import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import daterangepicker from "bootstrap-daterangepicker";
import locales from "./translations";

const DateRangePicker = ({ placeholder, options, language = "en", classList = "form-control", style }) => {
    const datepickerInput = useRef(null);

    useEffect(() => {
        const localeOptions = { ...locales[language] || locales.en };

        if (Boolean(options.timePicker)) localeOptions.format += " HH:MM"

        const mergedOptions = { ...options, locale: localeOptions };

        let dateRangePicker = new daterangepicker(datepickerInput.current, mergedOptions);

        if (options.onChange) {
            dateRangePicker.element.on('apply.daterangepicker', (event, picker) => {
                options.onChange(picker.startDate, picker.endDate)
                    .then(() => {
                    })
                    .catch(() => {
                        let format = 'DD/MM/YYYY'

                        dateRangePicker.setStartDate(picker.oldStartDate.format(format))
                        dateRangePicker.setEndDate(picker.oldEndDate.format(format))
                    })
            });
        }
    }, [])

    return (
        <input style={style} className={classList} placeholder={placeholder} ref={datepickerInput} />
    )
}

DateRangePicker.propTypes = {
    alwaysShowCalendars: PropTypes.bool,
    applyButtonClasses: PropTypes.string,
    autoApply: PropTypes.bool,
    autoUpdateInput: PropTypes.bool,
    buttonClasses: PropTypes.string,
    cancelButtonClasses: PropTypes.string,
    drops: PropTypes.oneOf(['down', 'up', 'auto']),
    endDate: PropTypes.string,
    isCustomDate: PropTypes.func,
    onChange: PropTypes.func,
    isInvalidDate: PropTypes.func,
    linkedCalendars: PropTypes.bool,
    locale: PropTypes.object,
    maxDate: PropTypes.string,
    maxSpan: PropTypes.object,
    maxYear: PropTypes.number,
    minDate: PropTypes.string,
    minYear: PropTypes.number,
    opens: PropTypes.oneOf(['left', 'right', 'center']),
    parentEl: PropTypes.string,
    ranges: PropTypes.object,
    showCustomRangeLabel: PropTypes.bool,
    showDropdowns: PropTypes.bool,
    showISOWeekNumbers: PropTypes.bool,
    showWeekNumbers: PropTypes.bool,
    singleDatePicker: PropTypes.bool,
    startDate: PropTypes.string,
    timePicker: PropTypes.bool,
    timePicker24Hour: PropTypes.bool,
    timePickerIncrement: PropTypes.number,
    timePickerSeconds: PropTypes.bool
}

export default DateRangePicker;
